const $btnToNextSection = $('.btn-scroll-down');
const $btnToNextSectionInHero = $('.js-btn-scroll-down-in-hero');
const $btnToNextSectionInCustomSection = $('.js-btn-scroll-down-custom-section');
const $btnToNextSectionInVc = $('.js-btn-scroll-down-vc');
const $btnToTop = $('.btn-scroll-top');

$btnToNextSectionInHero.on('click', function(e) {
	e.preventDefault();

	let scrollToHero = $(this).closest('.hero').next().offset().top;

	$('html,body').animate({
		scrollTop: scrollToHero
	}, 700);
});

$btnToNextSectionInVc.on('click', function(e) {
	e.preventDefault();

	let scrollToHero = $(this).closest('.vc_row').next().offset().top;

	$('html,body').animate({
		scrollTop: scrollToHero
	}, 700);
});

$btnToNextSectionInCustomSection.on('click', function(e) {
	e.preventDefault();

	let scrollToHero = $(this).closest('.section-scroll').next().offset().top;

	$('html,body').animate({
		scrollTop: scrollToHero
	}, 700);
});

$btnToTop.on('click', function(e) {
	e.preventDefault();

	$('html,body').animate({
		scrollTop : 0
	}, 700);
});
