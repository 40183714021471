import { $win } from '../utils/globals';

$win.on('load scroll', function() {
	const $sectionVisible = $('.section-visible');

	$sectionVisible.each(function() {
		let $this = $(this);

		if (!$this.hasClass('in-viewport') && ($win.scrollTop() + $win.outerHeight() - ($win.outerHeight() / 2)) > $this.offset().top && $win.width() > 767) {
			$this.addClass('in-viewport');

			const $el = $this.find('.approach');

			$el.each(function(i){
				const $that = $(this);

				setTimeout(function(){
					$that.addClass('is-active')
				}, i * 800);
			});
		}

		if (!$this.hasClass('in-viewport') && ($win.scrollTop() + $win.outerHeight() - 20) > $this.offset().top && $win.width() < 766) {
			$this.addClass('in-viewport');

			const $el = $this.find('.approach');

			$el.each(function(i){
				const $that = $(this);

				setTimeout(function(){
					$that.addClass('is-active')
				}, i * 800);
			});
		}
	});
});
