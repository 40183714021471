/*
 * Polyfills
 */
import './polyfills/object-fit';

/*
 * Modules
 */
import './modules/hero-fullheight';
import './modules/slider-case-studies';
import './modules/scroll-to';
import './modules/approaches';
import './modules/navigation';
import './modules/header';
import './modules/add-class-on-load';
import './modules/fadeout';
import './modules/podcast-ajax';
