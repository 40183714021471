import { $win } from '../utils/globals';


$win.on('load scroll', function() {
	const $fadedOut = $('.section-fade');

	$fadedOut.each(function() {
		let $this = $(this);

		if (!$this.hasClass('is-animated') && ($win.scrollTop() + $win.outerHeight() - 350) > $this.offset().top && $win.width() > 767) {
			$this.addClass('is-animated');
		}

		if (!$this.hasClass('is-animated') && ($win.scrollTop() + $win.outerHeight() - 20) > $this.offset().top && $win.width() < 766) {
			$this.addClass('is-animated');
		}
	});
});
