import { $document } from '../utils/globals';

function ajaxSearchPodcasts() {
	$('#podcast-search').on('submit', function(event) {
		event.preventDefault();

		const ajaxUrl = $(this).attr('action');
		const $ajaxContainer = $('.section-forks .section__body');

		$.ajax({
			url: ajaxUrl,
			method: 'GET',
			data: {
				'podcast_search': $('#field-search-episodes').val(),
			},
			beforeSend: function() {
				$ajaxContainer.html('<i class="ico-loading"></i>');
			},
			success: function(response) {
				if (response === 'error') {
					$ajaxContainer.html('<h2 class="section__title">No results</h2>');
				} else {
					$ajaxContainer.append($(response).find('.section-forks .section__body'));
				}
			},
			error: function() {
				$ajaxContainer.html('<h2 class="section__title">An error has occurred. Please reload the page and try again.</h2>');
			},
			complete: function() {
				$ajaxContainer.find('.ico-loading').remove();

				window.history.replaceState(null, document.title, `${ajaxUrl}?podcast_search=${$('#field-search-episodes').val()}`);
			}
		});
	});
}

function ajaxLoadMorePodcasts() {
	$document.on('click', '.js-load-more-podcasts', function(event) {
		event.preventDefault();
		const search = window.location.search.replace('?', '').split('=');
		const $this = $(this);

		const $ajaxContainer = $this.closest('.forks-wrap');
		const forksWrapIndex = $ajaxContainer.index();

		const theData = {
			'offset': $ajaxContainer.find('.fork').length,
			'limit': $ajaxContainer.find('.fork').length + 4,
			'podcast_search': search[1],
		}

		$.ajax({
			url: $this.attr('href'),
			method: 'GET',
			data: theData,
			beforeSend: function() {
				$ajaxContainer.append('<i class="ico-loading"></i>');
			},
			success: function(response) {
				if (response === 'error') {
					$ajaxContainer.find('section__actions').remove();
				} else {
					const $forks = $(response).find('.section-forks .forks-wrap').eq(forksWrapIndex).find('.fork');
					$forks.each((index, elem) => {
						if (index < theData.offset || index > theData.offset + 2) {
							return;
						}

						$ajaxContainer.find('.forks').append($(elem));
					});

					if ($forks.length <= theData.offset + 3) {
						$ajaxContainer.find('.section__actions').remove();
					}
				}
			},
			error: function() {
				$ajaxContainer.html('<h2 class="section__title">An error has occurred. Please reload the page and try again.</h2>')
			},
			complete: function() {
				$ajaxContainer.find('.ico-loading').remove();
			}
		})
	});
}

ajaxSearchPodcasts();
ajaxLoadMorePodcasts();
