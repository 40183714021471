import { $win } from '../utils/globals';

$win.on('load scroll', () => {
	const winScrollTop = $win.scrollTop();
	const winHeight = $win.outerHeight();
	const heroHeight  = $('.hero').outerHeight();
	const headerHeight  = $('.header').outerHeight() + 20;
	let alt = false;

	$('[data-alt]').each((i, elem) => {
		const thisHeight = $(elem).outerHeight();
		const thisOffsetTop = $(elem).offset().top;
		const offset = $(elem).is('[data-offset]') ? $(elem).data('offset') : 0;

		if (winScrollTop > thisOffsetTop && winScrollTop < thisOffsetTop + thisHeight - offset) {
			alt = true;
			return false;
		}
		
		alt = false;
	});

	if ($('.hero').length) {
		const headerNext = $('.hero').next().offset().top;

		if (winScrollTop >= headerNext) {
			$('body').addClass('has-header-vertical-logo');
		} else {
			$('body').removeClass('has-header-vertical-logo');
		}
	}

	/*if (winScrollTop > (heroHeight - headerHeight)) {
		$('body').addClass('has-no-logo')
	} else {
		$('body').removeClass('has-no-logo')
	}*/

	$('body').toggleClass('has-color-header', alt);

	$('.vc_row-white-header').attr('data-alt', '');
});
